<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">ADD NEW ORDER TYPE</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-3"> 
                    <div class="v-modal-layout pr-3 comboModal" style="padding-right: 18px;max-height: 430px !important; overflow-x: hidden !important;overflow-y: scroll !important">
                        <div class="section mt-5">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Order Type Information</span>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group contactGroup">
                                        <input class="inputContact" name="order_type" v-validate="'required'" :maxlength="ordertypelength" required autocomplete="off" v-model="order_type" type="text"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Order Type<sup>*</sup></label>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('order_type')" style="display: inline !important">{{errors.first('order_type')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ordertypelength - order_type.length}} / {{ordertypelength}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group contactGroup">
                                        <input class="inputContact" name="description" v-validate="'required'" :maxlength="descriptionlength" required autocomplete="off" v-model="description" type="text"/>
                                        <span class="highlightContact"></span>
                                        <span class="barContact"></span>
                                        <label class="labelContact">Description<sup>*</sup></label>
                                        <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('description')" style="display: inline !important">{{errors.first('description')}}</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{descriptionlength - description.length}} / {{descriptionlength}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section mt-5">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Timings</span>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="contact-item row no-gutters align-items-center py-sm-4 pl-3" style="background:#00448b !important;">
                                        <div class="col multiple-heading-div">Day</div>
                                        <div class="col multiple-heading-div newSwitch"><el-switch class="pr-2" active-color="#ffffff" @change="handelAll()" v-model="selectAll"></el-switch>Open/Close</div>
                                        <div class="col multiple-heading-div">Opening Hours</div>
                                        <div class="col multiple-heading-div">Closing Hours</div>
                                    </div>
                                    <div class="add-member-modal pl-3">
                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Monday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="monday_status" @change="handleChange('Mon',monday_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_monday" placeholder="Select Start Time" :disabled="!monday_status"></el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_monday" :picker-options="{minTime: start_monday}" placeholder="Select End Time" :disabled="!monday_status"></el-time-picker>
                                            </div>
                                        </div>
                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Tuesday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="tue_status" @change="handleChange('Tue',tue_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_tue" placeholder="Select Start Time" :disabled="!tue_status">
                                                </el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_tue" placeholder="Select End Time" :picker-options="{minTime: start_tue}" :disabled="!tue_status">
                                                </el-time-picker>
                                            </div>
                                        </div>
                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Wednesday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="wed_status" @change="handleChange('Wed',wed_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_wed" placeholder="Select Start Time" :disabled="!wed_status">
                                                </el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_wed" :picker-options="{minTime: start_wed}" placeholder="Select End Time" :disabled="!wed_status">
                                                </el-time-picker>
                                            </div>
                                        </div>

                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Thursday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="thu_status" @change="handleChange('Thu',thu_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_thu" placeholder="Select Start Time" :disabled="!thu_status">
                                                </el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_thu"  :picker-options="{minTime: start_thu}" placeholder="Select End Time" :disabled="!thu_status">
                                                </el-time-picker>
                                            </div>
                                        </div>

                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Friday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="fri_status" @change="handleChange('Fri',fri_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_fri" placeholder="Select Start Time" :disabled="!fri_status">
                                                </el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_fri" :picker-options="{minTime: start_fri}" placeholder="Select End Time" :disabled="!fri_status">
                                                </el-time-picker>
                                            </div>
                                        </div>

                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Saturday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="sat_status" @change="handleChange('Sat',sat_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_sat" placeholder="Select Start Time" :disabled="!sat_status">
                                                </el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_sat" :picker-options="{minTime: start_sat}" placeholder="Select End Time" :disabled="!sat_status">
                                                </el-time-picker>
                                            </div>
                                        </div>
                                        <div class="contact-item row no-gutters align-items-center py-sm-4">
                                            <div class="col text-truncate font-weight-bold product-name">Sunday</div>
                                            <div class="col email text-truncate">
                                                <el-switch v-model="sun_status" @change="handleChange('Sun',sun_status)"></el-switch>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="start_sun" placeholder="Select Start Time" :disabled="!sun_status">
                                                </el-time-picker>
                                            </div>
                                            <div class="col time-pick text-truncate">
                                                <el-time-picker v-model="end_sun" :picker-options="{minTime: start_sun}" placeholder="Select End Time" :disabled="!sun_status">
                                                </el-time-picker>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <a class="btn btn-danger btn-smm text-white" @click="cancel()">CANCEL</a>
                    <button class="btn btn-success btn-smm text-uppercase text-white ml-5" @click="save()">SAVE</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import YieldManager from './mixins/yieldmanager'
export default {
    props:['modal_name'],
    mixins: [YieldManager],
    data(){
        return{
            selectAll:false,
            ordertypelength:45,
            descriptionlength:1000,
            order_type:'',
            description:'',
            monday_status:false,
            start_monday:'',
            end_monday:'',
            tue_status:false,
            start_tue:'',
            end_tue:'',
            wed_status:false,
            start_wed:'',
            end_wed:'',
            thu_status:false,
            start_thu:'',
            end_thu:'',
            fri_status:false,
            start_fri:'',
            end_fri:'',
            sat_status:false,
            start_sat:'',
            end_sat:'',
            sun_status:false,
            start_sun:'',
            end_sun:''
        }
    },
    created() {
        const dict = {
            custom: {
                order_type: {
                    required: () => 'Order Type is Required',
                },
                description:{
                    required: () => 'Description is Required'
                }
            }
        }
        this.$validator.localize('en', dict)
    },
    methods:{
        cancel(){
            this.clearAllData()
            this.$modal.hide(this.modal_name)
        },
        handelAll(){
            if(this.selectAll){
                this.monday_status=true
                this.tue_status=true
                this.wed_status=true
                this.thu_status=true
                this.fri_status=true
                this.sat_status=true
                this.sun_status=true
            }else{
                this.monday_status=false
                this.start_monday=''
                this.end_monday=''
                this.tue_status=false
                this.start_tue=''
                this.end_tue=''
                this.wed_status=false
                this.start_wed=''
                this.end_wed=''
                this.thu_status=false
                this.start_thu=''
                this.end_thu=''
                this.fri_status=false
                this.start_fri=''
                this.end_fri=''
                this.sat_status=false
                this.start_sat=''
                this.end_sat=''
                this.sun_status=false
                this.start_sun=''
                this.end_sun=''
            }
        },
        clearAllData(){
            this.order_type=''
            this.description=''
            this.monday_status=false
            this.start_monday=''
            this.end_monday=''
            this.tue_status=false
            this.start_tue=''
            this.end_tue=''
            this.wed_status=false
            this.start_wed=''
            this.end_wed=''
            this.thu_status=false
            this.start_thu=''
            this.end_thu=''
            this.fri_status=false
            this.start_fri=''
            this.end_fri=''
            this.sat_status=false
            this.start_sat=''
            this.end_sat=''
            this.sun_status=false
            this.start_sun=''
            this.end_sun=''
            this.selectAll = false
        },
        convert(str){
            var month, day, year, hours, minutes, seconds;
            var date = new Date(str),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
            hours = ("0" + date.getHours()).slice(-2);
            minutes = ("0" + date.getMinutes()).slice(-2);
            seconds = ("0" + date.getSeconds()).slice(-2);
            var mySQLTime = [hours, minutes].join(":");
            return [mySQLTime].join(" ");
        },
        save(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.confirmSave()
                }else{
                    // this.$toasted.global.error('Please Fill All The Required Fields')
                }
            })
        },
        async confirmSave(){
            try {
                this.create_json.timings.forEach((el,index)=>{
                    if(!el.status){
                        el.opening_hours = ''
                        el.closing_hours = ''
                    }else{
                        if(el.opening_hours == 'aN:aN'){
                            el.opening_hours = this.convert(new Date())
                        }
                        if(el.closing_hours == 'aN:aN'){
                            el.closing_hours = this.convert(new Date())
                        }
                    }
                })
                let res = await this.addOrderType(this.create_json)
                this.$swal({
                    title: res.message,
                    text: '',
                    type: 'success'
                })
                this.$emit('saved')
                this.cancel()
            } catch (reason) {
                this.$swal({
                    title: "Order Type Already Exists",
                    text: '',
                    type: 'warning'
                })
            }
        },
        handleChange(type,status){
            if(!status){
                if(type == 'Mon'){
                    this.start_monday = ''
                    this.end_monday = ''
                }else if(type == 'Tue'){
                    this.start_tue = ''
                    this.end_tue = ''
                }else if(type == 'Wed'){
                    this.start_wed = ''
                    this.end_wed = ''
                }else if(type == 'Thu'){
                    this.start_thu = ''
                    this.end_thu = ''
                }else if(type == 'Fri'){
                    this.start_fri = ''
                    this.end_fri = ''
                }else if(type == 'Sat'){
                    this.start_sat = ''
                    this.end_sat = ''
                }else if(type == 'Sun'){
                    this.start_sun = ''
                    this.end_sun = ''
                }
            }
            if(this.monday_status && this.tue_status && this.wed_status && this.thu_status && this.fri_status && this.sat_status && this.sun_status){
                this.selectAll = true
            }else{
                this.selectAll = false
            }
        }
    },
    watch:{
        'start_monday'(newvalue,oldValue){
            if(newvalue){
                if(this.end_monday > this.start_monday){
                    this.start_monday = this.end_monday
                }else if(this.end_monday != ''){
                    this.end_monday = this.start_monday
                }
            }
        },
        'end_monday'(newvalue,oldValue){
            if(newvalue){
                if(this.end_monday < this.start_monday){
                    this.end_monday = this.start_monday
                }
            }
        },
        'start_tue'(newvalue,oldValue){
            if(newvalue){
                if(this.end_tue > this.start_tue){
                    this.start_tue = this.end_tue
                }else if(this.end_tue != ''){
                    this.end_tue = this.start_tue
                }
            }
        },
        'end_tue'(newvalue,oldValue){
            if(newvalue){
                if(this.end_tue < this.start_tue){
                    this.end_tue = this.start_tue
                }
            }
        },
        'start_wed'(newvalue,oldValue){
            if(newvalue){
                if(this.end_wed > this.start_wed){
                    this.start_wed = this.end_wed
                }else if(this.end_wed != ''){
                    this.end_wed = this.start_wed
                }
            }
        },
        'end_wed'(newvalue,oldValue){
            if(newvalue){
                if(this.end_wed < this.start_wed){
                    this.end_wed = this.start_wed
                }
            }
        },
        'start_thu'(newvalue,oldValue){
            if(newvalue){
                if(this.end_thu > this.start_thu){
                    this.start_thu = this.end_thu
                }else if(this.end_thu != ''){
                    this.end_thu = this.start_thu
                }
            }
        },
        'end_thu'(newvalue,oldValue){
            if(newvalue){
                if(this.end_thu < this.start_thu){
                    this.end_thu = this.start_thu
                }
            }
        },
        'start_fri'(newvalue,oldValue){
            if(newvalue){
                if(this.end_fri > this.start_fri){
                    this.start_fri = this.end_fri
                }else if(this.end_fri != ''){
                    this.end_fri = this.start_fri
                }
            }
        },
        'end_fri'(newvalue,oldValue){
            if(newvalue){
                if(this.end_fri < this.start_fri){
                    this.end_fri = this.start_fri
                }
            }
        },
        'start_sat'(newvalue,oldValue){
            if(newvalue){
                if(this.end_sat > this.start_sat){
                    this.start_sat = this.end_sat
                }else if(this.end_sat != ''){
                    this.end_sat = this.start_sat
                }
            }
        },
        'end_sat'(newvalue,oldValue){
            if(newvalue){
                if(this.end_sat < this.start_sat){
                    this.end_sat = this.start_sat
                }
            }
        },
        'start_sun'(newvalue,oldValue){
            if(newvalue){
                if(this.end_sun > this.start_sun){
                    this.start_sun = this.end_sun
                }else if(this.end_sun != ''){
                    this.end_sun = this.start_sun
                }
            }
        },
        'end_sun'(newvalue,oldValue){
            if(newvalue){
                if(this.end_sun < this.start_sun){
                    this.end_sun = this.start_sun
                }
            }
        },
    },
    computed:{
        create_json(){
            return{
                order_type:this.order_type,
                description:this.description,
                timings:[
                    {
                        day:'Monday',
                        status:this.monday_status,
                        opening_hours:this.convert(this.start_monday),
                        closing_hours:this.convert(this.end_monday)
                    },
                    {
                        day:'Tuesday',
                        status:this.tue_status,
                        opening_hours:this.convert(this.start_tue),
                        closing_hours:this.convert(this.end_tue)
                    },
                    {
                        day:'Wednesday',
                        status:this.wed_status,
                        opening_hours:this.convert(this.start_wed),
                        closing_hours:this.convert(this.end_wed)
                    },
                    {
                        day:'Thursday',
                        status:this.thu_status,
                        opening_hours:this.convert(this.start_thu),
                        closing_hours:this.convert(this.end_thu)
                    },
                    {
                        day:'Friday',
                        status:this.fri_status,
                        opening_hours:this.convert(this.start_fri),
                        closing_hours:this.convert(this.end_fri)
                    },
                    {
                        day:'Saturday',
                        status:this.sat_status,
                        opening_hours:this.convert(this.start_sat),
                        closing_hours:this.convert(this.end_sat)
                    },
                    {
                        day:'Sunday',
                        status:this.sun_status,
                        opening_hours:this.convert(this.start_sun),
                        closing_hours:this.convert(this.end_sun)
                    }
                ]
            }
        }
    }
}
</script>
<style scoped>
.section {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.outlet-hours {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #303031;
  padding: 20px 0px 10px;
}
.time-pick > .el-date-editor.el-input,
.time-pick > .el-date-editor.el-input__inner {
  width: 140px !important;
}
.time-pick > .el-date-editor.el-input > span.el-input__prefix,
.el-input__prefix {
  display: none !important;
}
.time-pick
  > .el-date-editor.el-input
  > span.el-input__prefix
  > .el-input__icon {
  display: none !important;
}
.el-input__icon {
  line-height: 25px !important;
}
</style>